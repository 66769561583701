<nz-modal [nzZIndex]="300" [(nzVisible)]="this.visible" [nzContent]="modalContent" [nzFooter]="null" [nzClassName]="'modal'"
    (nzOnCancel)="handleCancel()">
    <ng-template #modalContent class="modal">
        <div class="modal-container">
            <div class="form-modal">
                <div class="top">
                    <h1 class="form-heading">{{title}}</h1>
                    <!-- <div class="close" (click)="this.handleCancel()">
                        <span>&times;</span>
                    </div> -->
                </div>
                <div class="form-box full-width">
                    <p class="form-subheading">{{subTitle}}</p>
                    <app-error-message [message]="this.errorMessage"></app-error-message>
                </div>
                <div class="full-width action-align">
                    <div class="buttons full-width">
                        <button class="button-cancel action-button full-width" (click)="this.handleCancel()">Cancel</button>
                        <button class="action-button full-width" [ngClass]="actionType" [ladda]="processLoading" (click)="this.handleOk()">{{actionText}}</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</nz-modal>