import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  constructor(private elementRef: ElementRef) {}
  showDropdown = false;
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.options = changes['options'].currentValue;
    }
  }

  @Input() option: any;
  @Input() options: any[] = [];
  @Input() label: any = '';
  @Input() placeholder: any = '';
  @Input() custom: boolean = false;
  @Input() error: any = '';
  @Input() customValue: boolean = false;

  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  toggleDropdown = () => {
    this.showDropdown = !this.showDropdown;
  };
  closeDropdown = () => {
    this.showDropdown = false;
  };
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }
  selectOption = (opt: any) => {
    this.option = opt;
    this.error = '';
    this.select.emit(opt);
    this.closeDropdown();
  };
}
