<div class="flex flex-col w-full">
  <div class="flex flex-col gap-[6px]">
    <span class="text-[#6B7280] text-[14px] font-[800]">{{ label }}</span>
    <div class="relative w-full min-h-[52px]">
      <div
        class="flex flex-col bg-[#F4F5F7] min-h-[52px] px-[18px] rounded-[10px]  gap-[4px] overflow-hidden"
      >
        <span
          (click)="toggleDropdown()"
          class="cursor-pointer  text-[14px] {{
            !custom && !option ? 'text-[#9FA6B2]' : 'text-[#374151]'
          }} font-[400]  w-full h-full min-h-[52px] flex items-center justify-between "
        >
          {{ option ? option : customValue ? placeholder.value : placeholder }}
          <img
            class="h-[14px]"
            src="../../../../assets/icons/chevron.svg"
            alt=""
          />
        </span>
        <span
          *ngIf="customValue"
          class="text-[#6B7280] whitespace-nowrap text-[10px] truncate"
          >{{ placeholder.helper }}</span
        >
      </div>
      <span class="text-[12px] min-h-[14px] font-[400] text-red-500"
        >{{ error }}
      </span>
      <div
        *ngIf="showDropdown"
        class="absolute z-[999999] flex flex-col bg-[#fff] shadow-md p-3 w-full rounded-b-[10px] {{
          !custom ? 'max-h-[350px] overflow-y-auto' : ''
        }}"
      >
        <span *ngIf="!custom" class="w-full">
          <span
            *ngFor="let opt of options"
            (click)="selectOption(opt)"
            class="text-[#6B7280] text-[14px] hover:bg-[#f4f5f756] h-[27px] flex items-center font-[800] cursor-pointer"
          >
            {{ opt }}
          </span>
        </span>
        <span *ngIf="custom" (click)="toggleDropdown()" class="w-full">
          <ng-content></ng-content>
        </span>
      </div>
    </div>
  </div>
</div>
