import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() processLoading: boolean = false;
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() actionText: string = '';
  @Input() actionType: string = 'button-danger';
  @Input() confirmData: any = {};
  @Input() errorMessage: string = '';
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() actionClicked: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {}

  handleCancel(): void {
    this.visible = false;
    this.errorMessage = '';
    this.visibleChange.emit(this.visible);
  }

  handleOk(): void {
    // this.visible = false;
    // this.visibleChange.emit(this.visible);
    this.errorMessage = '';
    this.actionClicked.emit(this.confirmData);
  }
}
