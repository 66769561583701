<div class="flex flex-col min-h-[450px] items-center justify-center relative">
  <div class="relative h-[240px] flex items-center justify-center">
    <img
      class="w-[183px] h-[80%]"
      src="../../../../assets/images/empty.svg"
      alt=""
    />
    <div
      class="flex flex-col items-center justify-center absolute bottom-0 text-center"
    >
      <span class="text-[#252F3F] whitespace-nowrap text-[16px] font-[500]">{{ title }}</span>
      <span class="text-[#6B7280] text-[12px] font-[400]">{{ label }}</span>
    </div>
  </div>
</div>
